<template>
  <footer class="text-center py-4 md:py-6 bg-blueSecondary dark:bg-gray-700 text-white text-base">
    <div class="container mx-auto px-4 md:px-0">
      <span>
        Copyright &copy; 2022
        <span class="font-bold">
          PT Cels Technology Indonesia.</span>
      </span>
      All rights reserved.
    </div>
  </footer>
</template>

<script>
export default {
  props: {},
  setup() {},
};
</script>
