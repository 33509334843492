<template>
  <router-link
    class="block md:inline-block md:px-4 py-2 hover:opacity-80 transition-colors duration-300"
    :to="to"
  >
    <span class="flex items-center gap-2">
      <i :class="icon" class="md:hidden" />
      <span>{{ label }}</span>
    </span>
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>
