<template>
  <div class="contact pt-24 px-6 md:px-16">
    <!-- TITLE -->
    <h2
      class="text-4xl font-semibold text-center text-gray-800
      dark:text-white pt-10"
    >
      Get in <span class="text-blueSecondary">touch</span>
    </h2>
    <p class="mt-3 text-center text-gray-600 dark:text-gray-400">
      Lorem ipsum dolor sit amet consectetur, adipisicing elit.
    </p>
    <!-- CONTENT -->
    <div class="container mx-auto flex flex-col md:flex-row py-16 md:px-16 gap-16">
      <!-- Form -->
      <div class="flex-1">
        <div class="mt-6 ">
          <div class="items-center grid md:grid-cols-2 gap-4">
            <div class="w-full">
              <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                Name
              </label>

              <input
                class="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300
                rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600
                focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                type="text"
              >
            </div>

            <div class="w-full">
              <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
                E-mail
              </label>

              <input
                class="block w-full px-4 py-2 text-gray-700 bg-white border border-gray-300
                rounded-md dark:bg-gray-800 dark:text-gray-300 dark:border-gray-600
                focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
                type="email"
              >
            </div>
          </div>

          <div class="w-full mt-4">
            <label class="block mb-2 text-sm font-medium text-gray-600 dark:text-gray-200">
              Message
            </label>
            <textarea
              class="block w-full h-64 px-4 py-2 text-gray-700 bg-white border
              border-gray-300 rounded-md dark:bg-gray-800 dark:text-gray-300
              dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500
              focus:outline-none focus:ring"
            />
          </div>

          <div class="flex justify-center mt-6">
            <button
              class="px-4 py-2 text-white transition-colors duration-200 transform
              bg-orangeSecondary rounded-md hover:bg-blueSecondary focus:outline-none
              focus:bg-gray-600"
            >
              Send Message
            </button>
          </div>
        </div>
        <!-- /Form -->
      </div>
      <div class="flex-1 rounded-lg">
        <!-- Maps -->
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d742.7137465428441!2d106.64474304355315!3d-6.314173952113243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69e5a8c91b0a3f%3A0x23402adf9ebc02ad!2sCels%20Teknologi%20Indonesia!5e0!3m2!1sen!2sid!4v1643080011546!5m2!1sen!2sid"
          width="600"
          height="450"
          style="border:0;"
          allowfullscreen=""
          loading="lazy"
          class="rounded-2xl shadow-xl w-full"
        />
        <!-- /Maps -->
      </div>
    </div>
  </div>
</template>
