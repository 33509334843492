<template>
  <div
    id="navbar"
    class="p-4 md:py-6 md:px-16 bg-white shadow-lg
    fixed w-full z-30 transition-colors duration-300"
    :class="{ 'bg-transparent shadow-none' : !showWhiteBg,
              'dark:bg-gray-800' : showWhiteBg, }"
  >
    <div
      class="container w-full mx-auto flex flex-col items-center justify-between
      md:flex-row"
    >
      <div class="flex w-full justify-between items-center">
        <div
          class="text-3xl font-bold cursor-pointer"
          @click="$router.push('/')"
        >
          <img
            src="@/assets/logo.svg"
            alt="logo"
            class="h-10"
          >
        </div>
        <div>
          <button
            class="md:hidden"
            @click="toggleMenu"
          >
            <i class="far fa-bars text-xl dark:text-white" />
          </button>
        </div>
      </div>
      <div
        id="navmenu"
        class="bg-white dark:bg-gray-800 md:mt-0 md:bg-transparent md:dark:bg-transparent
        text-gray-700 dark:text-white w-full md:w-max md:space-x-5 md:flex md:items-center
        font-medium nav-menu"
        :class="{ 'md:text-gray-700' : $route.path !== '/',
                  'md:dark:text-white' : $route.path !== '/'}"
      >
        <button @click="toggleMenu">
          <i class="fas fa-times md:hidden absolute top-6 right-8 text-2xl" />
        </button>
        <NavbarLink
          to="/"
          icon="fas fa-home-alt"
          label="Home"
          class="nav-item"
          :class="{ 'text-bluePrimary' : $route.path === '/' }"
          @click.stop="hide()"
        />
        <NavbarLink
          to="/about"
          icon="fas fa-users"
          label="About"
          class="nav-item"
          :class="{ 'text-bluePrimary' : $route.path === '/about' }"
          @click.stop="hide()"
        />
        <NavbarLink
          to="/contact"
          icon="fas fa-envelope"
          label="Contact"
          class="mx-4 px-[1.65rem] py-4 md:py-3 bg-blueSecondary rounded-lg text-white md:p-3
          hover:bg-orangeSecondary hover:text-gray-100 transition-all duration-300"
          @click.stop="hide()"
        />
        <button @click="changeTheme">
          <span class="flex md:inline-block items-center nav-item gap-2 md:px-2">
            <i class="fas fa-moon text-xl text-gray-800 dark:text-gray-100" />
            <p class="md:hidden">Change theme</p>
          </span>
        </button>
      </div>
      <div
        class="fixed inset-0 opacity-0 bg-black md:bg-transparent transition-all
        duration-300"
        :class="{ 'opacity-30 block z-20 md:opacity-0 md:z-0' : toggleTheme,
                  'hidden' : !toggleTheme }"
        @click.self="hide"
      />
      <div class="relative">
        <toggle-dark @dark-mode-system="darkModeSystem" @hide="hide" @isDark="isDark" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import NavbarLink from './NavbarLink.vue';
import ToggleDark from '../ToggleComponent.vue';

const sun = 'fas fa-sun';
const moon = 'fa fa-moon';

export default {
  components: {
    NavbarLink,
    ToggleDark,
  },
  props: { toggleTheme: { type: Boolean } },
  setup() {
    const showWhiteBg = ref(false);

    document.addEventListener('scroll', () => {
      const topPosition = document.body.getBoundingClientRect().top;

      if (topPosition < -150) {
        showWhiteBg.value = true;
      }
      else {
        showWhiteBg.value = false;
      }
    });

    return {
      showWhiteBg,
      sun,
      moon,
    };
  },
  computed: {
    dark() {
      return JSON.parse(localStorage.getItem('darkMode'));
    },
  },
  methods: {
    toggleMenu() {
      document.querySelector('.nav-menu').classList.toggle('show-menu');
    },
    changeTheme() {
      this.$emit('change-theme');
      document.querySelector('.nav-menu').classList.remove('show-menu');
    },
    hide() {
      this.$emit('hide');
    },
    darkModeSystem() {
      this.$emit('darkModeSystem');
    },
    isDark(val) {
      this.$emit('isDark', val);
    },
  },
};
</script>
