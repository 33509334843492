<template>
  <div
    class="flex flex-col h-full"
    :class="{ 'dark' : darkMode }"
  >
    <Navbar
      @change-theme="changeTheme"
      @dark-mode-system="darkModeSystem"
      @hide="hide"
      @isDark="isDark"
      :toggle-theme="toggleTheme"
    />
    <div class="flex-1 bg-gray-50 dark:bg-gray-900">
      <router-view />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from './components/layouts/NavbarComponent.vue';
import Footer from './components/layouts/FooterComponent.vue';

export default {
  components: {
    Navbar,
    Footer,
  },
  data() {
    return {
      darkMode: false,
      toggleTheme: false,
    };
  },
  watch: {
    darkMode() {
      localStorage.setItem('darkMode', JSON.stringify(this.darkMode));
    },
  },
  created() {
    if (localStorage.getItem('darkMode') === null) {
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.darkMode = true;
      }
      else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        this.darkMode = false;
      }
    }
    else {
      this.darkMode = JSON.parse(localStorage.getItem('darkMode'));
    }
  },
  methods: {
    isDark(val) {
      this.darkMode = val;
    },
    changeTheme() {
      document.querySelector('.toggle-theme').classList.toggle('show-toggle');
      this.toggleTheme = !this.toggleTheme;
    },
    darkModeSystem() {
      // localStorage.removeItem('darkMode');
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.darkMode = true;
      }
      else if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        this.darkMode = false;
      }
    },
    hide() {
      document.querySelector('.toggle-theme').classList.remove('show-toggle');
      this.toggleTheme = false;
    },
  },
};
</script>
