<template>
  <div
    class="flex flex-col items-center p-6 space-y-3 text-center bg-white shadow-2xl rounded-xl
    dark:bg-gray-800"
  >
    <i
      class="text-6xl text-blueSecondary"
      :class="icon"
    />

    <h1 class="text-2xl font-semibold text-gray-700 capitalize dark:text-white">
      {{ name }}
    </h1>

    <p class="text-gray-500 dark:text-gray-300">
      {{ description }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>
