<template>
  <div
    class="testimony-card bg-blueSecondary dark:bg-gray-800 rounded-2xl text-white
    flex flex-col md:justify-center gap-8 flex-1"
  >
    <div class=" md:flex-shrink-0 flex items-center">
      <img
        :src="photo"
        alt=""
        class="h-32 w-32 rounded-full"
      >
      <div class="ml-4">
        <h3 class="text-lg font-bold mt-4">
          {{ name }}
        </h3>
        <p class="text-sm opacity-70">
          {{ position }}
        </p>
      </div>
    </div>
    <div class="px-6">
      <i class="fad fa-quote-left text-white dark:text-orangeSecondary text-6xl" />
      <p class="text-sm md:text-lg font-semibold">
        {{ comment }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    photo: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    position: {
      type: String,
      required: true,
    },
    comment: {
      type: String,
      required: true,
    },
  },
};
</script>
