<template>
  <div>
    <!-- HERO -->
    <section
      class="container mx-auto pt-16 pb-6 md:pb-40 px-6 text-center md:text-left
      home"
    >
      <div class="flex flex-col md:flex-row gap-8">
        <div class="order-2 md:order-1">
          <h1
            class="font-bold text-3xl md:text-4xl lg:text-5xl
          text-blueSecondary dark:text-white md:w-96 lg:w-heroText mb-6"
          >
            We provide services to help you build your technological infrastructures.
          </h1>
          <p class="text-gray-500 font-medium text-sm md:w-64 lg:w-heroText mb-8 lg:mb-16">
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Blanditiis at quam, corporis
            aperiamfugit voluptatem nemo maxime alias quisquam quae quas aut impedit esse rem.
          </p>
          <div
            class="flex flex-col md:flex-row justify-center md:justify-start
            items-center gap-3"
          >
            <router-link
              to="/contact"
              class="rounded-lg py-3 px-8 text-white bg-orangeSecondary hover:bg-blueSecondary
              transition-all duration-300 font-semibold
              block w-max text-sm md:text-base"
            >
              Let's Talk!
            </router-link>
            <router-link
              to="/about"
              class="rounded-lg py-3 px-8 text-gray-600 bg-gray-300 hover:bg-gray-400
              transition-all duration-300 font-semibold
              block w-max text-sm md:text-base"
            >
              Learn More
            </router-link>
          </div>
        </div>
        <img
          src="../assets/banner.svg"
          alt="HeroImage"
          class="order-1 md:order-2 md:hidden"
        >
      </div>
    </section>
    <!-- HERO -->

    <!-- FEATURE -->
    <section class="px-6 md:px-16">
      <div class="container py-10 mx-auto">
        <h1
          class="text-3xl font-semibold text-center text-gray-800
          capitalize lg:text-4xl dark:text-white"
        >
          why they <br> choose <span class="text-blueSecondary">Us</span>?
        </h1>
        <div class="grid grid-cols-1 gap-8 mt-8 xl:mt-12 xl:gap-16 md:grid-cols-2 xl:grid-cols-3">
          <feature-card
            icon="fad fa-cogs"
            name="Performance"
            description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur tempore
            suscipit sapiente cum! Eligendi ipsam aliquid minus, odit consequuntur sed?"
          />
          <feature-card
            icon="fad fa-badge-check"
            name="Quality"
            description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur tempore
            suscipit sapiente cum! Eligendi ipsam aliquid minus, odit consequuntur sed?"
          />
          <feature-card
            icon="fad fa-shield"
            name="Security"
            description="Lorem ipsum, dolor sit amet consectetur adipisicing elit. Tenetur tempore
            suscipit sapiente cum! Eligendi ipsam aliquid minus, odit consequuntur sed?"
          />
        </div>
      </div>
    </section>
    <!-- /FEATURE -->

    <!-- TESTIMONIALS -->
    <section class="py-16 px-0">
      <h1
        class="text-3xl font-semibold text-center text-gray-800
        capitalize lg:text-4xl dark:text-white"
      >
        What They <br> <span class="text-blueSecondary">Say</span>?
      </h1>
      <div class="container mx-auto rounded-2xl overflow-hidden">
        <swiper
          :slides-per-view="screenW"
          :space-between="30"
          :pagination="{ clickable: true }"
          class="px-6 md:px-16 mt-16"
        >
          <swiper-slide>
            <testimony-card
              photo="https://picsum.photos/200"
              name="Jane Doe"
              position="Freelancer"
              comment="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias
              accusantium velit distinctio maiores ipsum eum debitis inventore, assumenda
              corporis voluptatibus!"
            />
          </swiper-slide>
          <swiper-slide>
            <testimony-card
              photo="https://picsum.photos/200"
              name="Marx Dane"
              position="Owner of Something"
              comment="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias"
            />
          </swiper-slide>
          <swiper-slide>
            <testimony-card
              photo="https://picsum.photos/200"
              name="John Doe"
              position="CEO of Bla"
              comment="Lorem ipsum dolor sit amet consectetur, adipisicing elit. Molestias
              accusantium velit distinctio maiores ipsum"
            />
          </swiper-slide>
        </swiper>
      </div>
    </section>
    <!-- /TESTIMONIALS -->
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';

import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css';
import FeatureCard from '../components/layouts/FeatureCard.vue';
import TestimonyCard from '../components/layouts/TestimonyCard.vue';

export default {
  components: {
    FeatureCard,
    TestimonyCard,
    Swiper,
    SwiperSlide,
  },
  setup() {
    return { modules: [Navigation, Pagination] };
  },
  data() {
    return { screenSize: null };
  },
  computed: {
    screenW() {
      if (this.screenSize > 768) {
        return 2;
      }
      return 1;
    },
  },
  created() {
    this.screenSize = window.innerWidth || document.documentElement.clientWidth
    || document.body.clientWidth;
  },
};

</script>
