<template>
  <div
    class="toggle-theme z-50"
  >
    <div
      class="toggle-item"
      @click="darkModeSystem(); hide()"
    >
      <div class="flex items-center gap-2">
        <i class="fad fa-desktop" />
        <p>System</p>
      </div>
      <span v-show="isSystem" class="block bg-bluePrimary rounded-full h-2 w-2" />
    </div>
    <div
      class="toggle-item"
      @click="toggleData(true); hide()"
    >
      <div class="flex items-center gap-2">
        <i class="fad fa-moon" />
        <p>Dark</p>
      </div>
      <span v-show="darkMode && !isSystem" class="block bg-bluePrimary rounded-full h-2 w-2" />
    </div>
    <div
      class="toggle-item"
      @click="toggleData(false); hide()"
    >
      <div class="flex items-center gap-2">
        <i class="fad fa-sun" />
        <p>Light</p>
      </div>
      <span
        v-show="!darkMode && !isSystem"
        class="block bg-bluePrimary rounded-full h-2 w-2"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      darkMode: false,
      isSystem: false,
    };
  },
  watch: {
    darkMode() {
      localStorage.setItem('darkMode', JSON.stringify(this.darkMode));
    },
  },
  created() {
    this.darkMode = JSON.parse(localStorage.getItem('darkMode'));
  },
  methods: {
    darkModeSystem() {
      this.$emit('darkModeSystem');
      this.isSystem = true;
    },
    hide() {
      this.$emit('hide');
    },
    toggleData(val) {
      this.darkMode = val;
      this.isSystem = false;
      // console.log(val);
      this.$emit('isDark', this.darkMode);
    },
  },
};
</script>
